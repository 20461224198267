/* TODO Move this to different component */
body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* TODO
    Move this to different component 
    Make the footer stick to the bottom of the page
*/
header,
footer {
    background-color: #192F55;
    color: #FFC300;
    text-align: center;
    padding: 1em;
}

.section {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

form {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
}

.form-section {
    background: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px;
}

.form-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #05a8e8;
    color: white;
    cursor: pointer;
    margin-top: 20px;
}